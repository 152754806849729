<script>

export default {

    props: {
        insightObj: {
            type: Object
        },
        category : String
    },

    data() {

    },

    methods: {
        toggle(event) {
            var toggleEl = event.target.parentElement.parentElement.nextSibling;

            if (toggleEl.getAttribute('class') == 'hide') {
                toggleEl.setAttribute('class', 'show');
                event.target.setAttribute('class', 'mdi mdi-arrow-up-thick');
            } else {
                toggleEl.setAttribute('class', 'hide');
                event.target.setAttribute('class', 'mdi mdi-arrow-down-thick');
            }
        }
    }

}
</script>
 
<template>

    <div>
        <div class="card">
            <div class="card-header align-items-center d-flex">
                <H4 class="text-danger">{{category}} Failed Tests</H4>

            </div>
            <!-- end card header -->

            <!-- card body -->
            <div class="card-body">
                <table v-for="(item, index) in insightObj.failedResults"
                    :key="index" class="table mb-3 mt-3">
                    <tr>
                        <th>{{ item.title }}<i class="mdi mdi-arrow-down-thick" @click="toggle($event)"></i>
                        </th>
                    </tr>
                    <tbody class="hide">
                        <tr>
                            <td>
                                {{ item.description }}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div
                    v-if="insightObj.failedResults && insightObj.failedResults.length === 0">
                    <p class="text-success">Congrats you have passed all of Google's {{category}} tests</p>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header align-items-center d-flex">
                <h4 class="text-success">{{category}} Passed Tests</h4>
            </div>
            <div class="card-body">
                <table v-for="(item, index) in insightObj.passedResults"
                    :key="index" class="table mb-3 mt-3">
                    <tr>
                        <th>{{ item.title }}<i class="mdi mdi-arrow-down-thick" @click="toggle($event)"></i>
                        </th>
                    </tr>
                    <tbody class="hide">
                        <tr>
                            <td>
                                {{ item.description }}
                                <a target="new" :href="item.learnMoreUri">Learn more</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>


<style scoped>
.hide {
    display: none;
    ;
}

.show {
    display: block;
}
</style>