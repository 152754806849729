<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import api from '@/services/secureHttps';
import InsightResults from "@/components/googleInsights/insightResults";

export default {
    props: {

        title: String,
        organisationId: {
            type: Number,
            required: true
        },
        websiteId: {
            type: Number,
            required: true
        },
        pageId: {
            type: Number,
            required: true
        },
        savedResultsUrl: {
            type : String,
            required: false,
            default: null
        }
    },
    components: {
        Layout,
        PageHeader,
        InsightResults
    },

    mounted() {
        console.log("seoInsights mounted", this.savedResultsUrl);
        if (typeof this.savedResultsUrl === 'undefined' || this.savedResultsUrl === null) {
            this.getData();
        } else {
            this.getSavedResults();
        }
    },

    data() {
        return {
            isAuditing: false,
            googleInsightResults: null,

            seoScoreConfig: {
                chart: {
                    height: 250,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '70%',
                        }
                    },
                },
                labels: ['SEO'],
            },

            bestPracticeConfig: {
                chart: {
                    height: 250,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '70%',
                        }
                    },
                },
                labels: ['Best Practice'],
            },

            performanceScoreConfig: {
                chart: {
                    height: 250,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '70%',
                        }
                    },
                },
                labels: ['Performance'],
            }
        };
    },

    methods: {
        getData() {
            this.isAuditing = true;
            console.log("!!!getting data");
            api.auditPage(this.websiteId, this.pageId)
                    .then(response => {
                        this.googleInsightResults = response.data;
                            console.log("googleInsightResults.googleInsights.fullScreenShot", this.googleInsightResults.googleInsights.fullScreenShot);
                    }).finally(() => {
                        this.isAuditing = false;
                    });
        },
        getSavedResults() {
            console.log("!!!getting savedresults ");
            this.isAuditing = true;
            api.callUrl(this.savedResultsUrl)
                    .then(response => {
                        this.googleInsightResults = response.data;
                        console.log("googleInsightResults.googleInsights.fullScreenShot", this.googleInsightResults.googleInsights.fullScreenShot);
                    }).finally(() => {
                        this.isAuditing = false;
                    });
        }
    }
}
</script>
            
<template>
    <Layout>
        <PageHeader :title="title" />

        <div v-if="isAuditing">
            <div class="row">
                <div class="col text-center" >
                    <div class="spinner-border text-primary"  style="width: 8rem; height: 8rem;"  role="status">
                    </div>
                    <p> <span>Running tests...</span></p>
                </div>
            </div>
        </div>

        <!-- Start google insights -->

        <div v-if="googleInsightResults">
            <!-- start SEO Test results -->
            <div class="row">
                <div class="col-8">
                    <h2>Desktop results</h2>

                    <div class="card">
                        <div class="card-header align-items-center d-flex">
                            <H4 class="text-danger">Overall Score</H4>

                        </div>
                        <!-- end card header -->

                        <!-- card body -->
                        <div class="card-body">
                            <div class="row">
                                <div class="col text-center">
                                    <apexchart type="radialBar" height="250" :options="seoScoreConfig"
                                        :series="[googleInsightResults.googleInsights.seo.score ]">
                                    </apexchart>
                                </div>
                                <div class="col text-center">
                                    <apexchart type="radialBar" height="250" :options="bestPracticeConfig"
                                        :series="[googleInsightResults.googleInsights.bestPractices.score]">
                                    </apexchart>
                                </div>
                                <div class="col text-center">
                                    <apexchart type="radialBar" height="250" :options="performanceScoreConfig"
                                        :series="[googleInsightResults.googleInsights.performance.score]">
                                    </apexchart>
                                </div>
                            </div>

                        </div>
                    </div>

                    <InsightResults :insightObj="googleInsightResults.googleInsights.seo" :category="'SEO'">
                    </InsightResults>

                    <!--start Best Practices-->
                    <InsightResults :insightObj="googleInsightResults.googleInsights.bestPractices"
                        :category="'Best practice'"></InsightResults>
                    <!--end Best Practices-->


                    <!--start Performance Results-->
                    <InsightResults :insightObj="googleInsightResults.googleInsights.performance"
                        :category="'Performance'"></InsightResults>
                    <!--end Performances-->

                </div>
               <div class="col-4 mb-5" v-if="typeof googleInsightResults.googleInsights.fullScreenShot !== 'undefined'">
                    <img class="img-fluid" :src="googleInsightResults.googleInsights.fullScreenShot.base64Img" />
                </div> 
            </div>
            <!-- End SEO Test results-->


        </div>


    </Layout>
</template>

